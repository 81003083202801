import { getList } from 'utils/API';
import { getQuery } from 'utils/globals/queries';

import { AGENCY_ARTIST_ROSTER } from 'constants/endpointConstants';

const fetchAgencyArtists = ({ agencyId }, cookies) => {
  const params = {
    queryParams: {
      agency_id: agencyId,
      limit: 1000,
      page: 1,
    },
  };

  return getList(AGENCY_ARTIST_ROSTER, params, cookies).then(response => response.data);
};

const queries = {
  getAgencyArtists: getQuery('GET_AGENCY_ARTISTS', fetchAgencyArtists),
};

export default queries;
